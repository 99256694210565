<template>
  <div>
    <a-modal
      :visible="visible"
      title="新增检测标准"
      :width="1000"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
        <a-row>
          <a-col :span="8">
            <a-form-item label="名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [
                      { required: true, message: '请输入名称' },
                      { max: 64, message: '超出最大长度(64)' },
                    ],
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="检验方式">
              <a-select
                v-decorator="[
                  'method',
                  {
                    rules: [{ required: true, message: '请选择检验方式' }],
                  },
                ]"
                :allowClear="true"
              >
                <a-select-option value="full_inspection">全检</a-select-option>
                <a-select-option value="sampling_inspection">抽检</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="启用状态">
              <a-switch
                v-decorator="[
                  'is_enabled',
                  {
                    valuePropName: 'checked',
                    initialValue: true,
                    rules: [{ required: true, message: '请选择启用状态' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="工序" :label-col="{ span: 2 }" :wrapper-col="{ span: 13 }">
              <ProcessMultSelect
                v-decorator="[
                  'process_set',
                  {
                    rules: [{ required: true, message: '请选择工序' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="检测项目" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
              <InspectionPointField
                v-decorator="[
                  'inspection_standard_point_items',
                  {
                    initialValue: [],
                    rules: [{ required: true, message: '请添加检测项目' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { inspectionStandardCreate } from "@/api/inspection";

export default {
  components: {
    ProcessMultSelect: () => import("@/components/ProcessMultSelect"),
    InspectionPointField: () => import("@/components/InspectionPointField"),
  },
  props: ["visible"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      dataForm: this.$form.createForm(this),
    };
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          this.confirmLoading = true;
          inspectionStandardCreate(values)
            .then((data) => {
              this.$emit("create", data);
              this.$message.success("创建成功");
              this.handleCancel();
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
  },
};
</script>

<style scoped></style>
