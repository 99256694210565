var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"新增检测标准","width":1000,"confirmLoading":_vm.confirmLoading,"destroyOnClose":true,"maskClosable":false},on:{"cancel":_vm.handleCancel,"ok":_vm.handleConfirm}},[_c('a-form',{attrs:{"form":_vm.dataForm,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }}},[_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  rules: [
                    { required: true, message: '请输入名称' },
                    { max: 64, message: '超出最大长度(64)' },
                  ],
                },
              ]),expression:"[\n                'name',\n                {\n                  rules: [\n                    { required: true, message: '请输入名称' },\n                    { max: 64, message: '超出最大长度(64)' },\n                  ],\n                },\n              ]"}],attrs:{"allowClear":true}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"检验方式"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'method',
                {
                  rules: [{ required: true, message: '请选择检验方式' }],
                },
              ]),expression:"[\n                'method',\n                {\n                  rules: [{ required: true, message: '请选择检验方式' }],\n                },\n              ]"}],attrs:{"allowClear":true}},[_c('a-select-option',{attrs:{"value":"full_inspection"}},[_vm._v("全检")]),_c('a-select-option',{attrs:{"value":"sampling_inspection"}},[_vm._v("抽检")])],1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"启用状态"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'is_enabled',
                {
                  valuePropName: 'checked',
                  initialValue: true,
                  rules: [{ required: true, message: '请选择启用状态' }],
                },
              ]),expression:"[\n                'is_enabled',\n                {\n                  valuePropName: 'checked',\n                  initialValue: true,\n                  rules: [{ required: true, message: '请选择启用状态' }],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"工序","label-col":{ span: 2 },"wrapper-col":{ span: 13 }}},[_c('ProcessMultSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'process_set',
                {
                  rules: [{ required: true, message: '请选择工序' }],
                },
              ]),expression:"[\n                'process_set',\n                {\n                  rules: [{ required: true, message: '请选择工序' }],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"检测项目","label-col":{ span: 2 },"wrapper-col":{ span: 22 }}},[_c('InspectionPointField',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'inspection_standard_point_items',
                {
                  initialValue: [],
                  rules: [{ required: true, message: '请添加检测项目' }],
                },
              ]),expression:"[\n                'inspection_standard_point_items',\n                {\n                  initialValue: [],\n                  rules: [{ required: true, message: '请添加检测项目' }],\n                },\n              ]"}]})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }